<template>
  <index />
</template>

<script>
import index from "./index.vue"
export default {
   name: 'enquiryManagement3',
   components: { index }
}
</script>

<style>

</style>