<template>
  <el-dialog
    title="投保确认"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    custom-class="mian-content"
  >
    <!-- 文件展示 -->
    <!-- <div class="upload-content" v-if="view || isView">
      <FileIcon
        v-for="(file, fi) in fileList"
        :key="fi"
        :suffix="file.fileType"
        :fileName="file.fileName"
        showDelet
        @deletFile="deletFile(fi)"
        @preView="preView(file)"
      ></FileIcon>
    </div> -->
    <div class="upload-content">
      <el-upload
        action="#"
        :auto-upload="false"
        :show-file-list="false"
        :on-change="uploadFile"
        accept=".jpg,.jpeg,.png,.pdf"
      >
        <el-button class="upload-content-btn">上传文件</el-button>
      </el-upload>
      <div class="upload-content-tips">
        支持格式：.jpg .jpeg .png .pdf ，单个文件不能超过5MB
      </div>
      <el-upload
        class="upload-content-file"
        drag
        action="#"
        :auto-upload="false"
        :show-file-list="false"
        :on-change="uploadFile"
        accept=".jpg,.jpeg,.png,.pdf"
        multiple
      >
        <!-- 文件展示 -->
        <div v-if="fileList.length">
          <FileIcon
            v-for="(file, fi) in fileList"
            :key="fi"
            :suffix="file.fileType"
            :fileName="file.fileName"
            showDelet
            @deletFile="deletFile(fi)"
            @preView="preView(file)"
          ></FileIcon>
        </div>
        <div v-else>
          <i class="el-icon-upload"></i>
          <div class="upload-content-file-text">
            拖拽到此或点击上方"上传文件"
          </div>
        </div>
      </el-upload>
    </div>

    <div slot="footer" class="mian-content-action">
      <el-button
        v-if="view || isView"
        class="mian-content-action-confir"
        @click="downloadAllFile"
        >一键下载</el-button
      >
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button class="mian-content-action-confir" @click="submit"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { OSSDirectPass } from "@/api/oss.js";
import {
  insNewEnquiryDetail,
  saveEnquiryList,
  insureOfflineConfirmation,
} from "@/api/policy.js";
import FileIcon from "@/components/DragMultipleUpload/FileIcon.vue";
import { Loading } from "element-ui";
import { downloadZIP } from "@/utils/index";
import { log } from "util";
export default {
  components: { FileIcon },
  model: {
    prop: "show",
    event: "change",
  },
  props: {
    view: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
    insid: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      ossdone: false,
      isView: false,
      fileList: [],
      addFileList: [],
      rawfiles: [],
      insData: {},
    };
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        }
      },
    },
    show(n) {
      this.fileList = [];
      this.rawfiles = [];
      this.addFileList = [];
      if (n) {
        if (this.view || this.isView) {
          insNewEnquiryDetail(this.insid).then((res) => {
            //this.insData = res.data;
            this.fileList = res.data.attachments["offline"] || [];
          });
        }
      }
      this.dialogVisible = n;
    },
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.fileList = [];
          this.rawfiles = [];
          this.addFileList = [];
          done();
        })
        .catch((_) => {});
    },
    submit() {
      let loading = Loading.service();
      insNewEnquiryDetail(this.insid).then((insres) => {
        let ensData = insres.data;
        let ossFiles = [];
        this.rawfiles.forEach((file) => {
          ossFiles.push(OSSDirectPass(file));
        });
        if (!this.rawfiles.length) {
          this.$emit("change", false);
        }
        Promise.all(ossFiles).then((res) => {
          if (this.isView || this.view) {
            // TODO 将新上传的文件放回(待测试)
            res.forEach((url, ui) => {
              this.addFileList[ui].url = url;
            });
            let aflen = this.addFileList.length || 0;
            let flen = this.fileList.length || 0;
            this.fileList = this.fileList.slice(0, flen - aflen);
            this.fileList = [...this.fileList, ...this.addFileList];
          } else {
            res.forEach((url, ui) => {
              this.fileList[ui].url = url;
            });
          }
          this.saveFile(ensData);
        });
      });
    },
    uploadFile(file) {
      //if (this.view) return;
      //console.log(file);
      if (file.status === "ready") {
        this.rawfiles.push(file);
        let suffix = this.getSuffix(file.name);
        if (this.isView || this.view) {
          this.addFileList.push({
            fileName: file.name,
            url: "",
            fileType: suffix,
            attachmentType: "offline",
          });
        }
        this.fileList.push({
          fileName: file.name,
          url: URL.createObjectURL(file.raw) || "",
          fileType: suffix,
          attachmentType: "offline",
        });
      }
    },
    downloadAllFile() {
      let downloads = this.fileList.map((file) => {
        return {
          name: file.fileName,
          ...file,
        };
      });
      downloadZIP(downloads, "投保确认文件");
    },
    //获取后缀名
    getSuffix(fileName) {
      let arr = fileName.split(".");
      if (arr.length) {
        return arr[arr.length - 1];
      }
      return "";
    },
    deletFile(i) {
      this.rawfiles.splice(i, 1);
      this.fileList.splice(i, 1);
    },
    preView(file) {},
    saveFile(ensData = {}) {
      if (!ensData.attachmentType.includes("offline")) {
        ensData.attachmentType = [ensData.attachmentType, "offline"].join(",");
      }
      ensData.attachments["offline"] = this.fileList;
      saveEnquiryList(ensData).then(() => {
        if (this.isView || this.view) {
          this.$emit("change", false);
          this.$emit("changeList", false);
        } else {
          insureOfflineConfirmation({ enquiryId: this.insid }).then(() => {
            this.$emit("change", false);
            this.$emit("changeList", false);
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.mian-content {
  border-radius: 8px;
  max-width: 640px;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 50px;
    background: #4278c9;
    display: flex;
    align-items: center;
    border-radius: 8px 8px 0px 0px;
    .el-dialog__title {
      color: #fff;
    }
  }
  .el-dialog__body {
    padding: 16px 32px;
  }
  .el-dialog__headerbtn {
    .el-dialog__close {
      color: #fff;
    }
  }
  &-action {
    &-confir {
      background: #4278c9;
      color: #ffffff;
    }
  }
}
.upload-content {
  display: column;
  font-size: 12px;
  font-weight: 300;
  color: #333333;
  &-btn {
    font-size: 14px;
    font-weight: 400;
    color: #0080ff;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #0080ff;
  }
  &-tips {
    margin: 8px 0 12px 0;
  }
  &-file {
    background: #f3f8ff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px dashed #d1e3fe;
    display: flex;
    .el-icon-upload {
      color: #4278c9;
      size: 40px;
    }
    &-text {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
    }

    .el-upload {
      width: 100%;
      border-radius: 8px;
      .el-upload-dragger {
        background-color: #f3f8ff;
        border-radius: 8px;
        border: none;
        width: auto;
      }
    }
  }
}
</style>
