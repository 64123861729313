<template>
  <!-- 询价单 -->
  <div>
    <div class="type-select">
      <div
        class="item"
        :class="{ active: curType === 0 }"
        @click="handleSelect(0)"
      >
        询价单
      </div>
      <div
        class="item"
        :class="{ active: curType === 1 }"
        @click="handleSelect(1)"
      >
        草稿箱
      </div>
    </div>
    <GeneralTable
      ref="GeneralTable"
      v-if="
        curType === 0 && handleHasPerms(`M26-view_${$route.meta.policyType}`)
      "
      :column="column"
      :option="option"
      :dataList="dataList"
      @import-click="importClick"
      @search-change="handleSearch"
      @export-data="handleExport"
      @del-change="handleDel"
      @add-change="handleEdit('add')"
      :totalNum="totalNum"
      tableName="enquiryManagement"
    >
      <!-- 统计插槽 -->
      <template slot="statistics">
        <statistics-box
          :statisticsList="statisticsList"
          v-loading="statisticsLoading"
        />
      </template>

      <template slot="operate-left">
        <el-button
          type="primary"
          size="mini"
          v-if="
            handleHasPerms(`M26-allocationCustomer_${$route.meta.policyType}`)
          "
          @click="handleAllocationCustomer"
          >分配客户</el-button
        >
      </template>
      <template slot="enquiryNo" slot-scope="{ item }">
        <div
          class="el-button polecy-button el-button--text el-button--small"
          style="text-decoration: underline; padding: 0"
          @click="handleEdit('details', item.enquiryNo, item.id)"
        >
          {{ item.enquiryNo }}
        </div>
      </template>
      <template slot="approvalStatusDesc" slot-scope="{ item }">
        <div
          :class="['approval-status', 'approval-status' + item.approvalStatus]"
        >
          {{ item.approvalStatusDesc }}
        </div>
      </template>
      <template slot="surePress" slot-scope="{ item }">
        <div :style="{ color: item.surePress ? '#00bc0d' : '#666' }">
          {{ item.surePress ? "已确认" : "未确认" }}
        </div>
      </template>
      <template slot="brandName" slot-scope="{ item }">
        {{
          item.isSingle !== false ? item.brandName : "多标的类型，详细查看详情"
        }}
      </template>
      <template slot="frameNo" slot-scope="{ item }">
        {{
          item.isSingle !== false ? item.frameNo : "多标的类型，详细查看详情"
        }}
      </template>
      <template slot="equipmentType" slot-scope="{ item }">
        {{
          item.isSingle !== false
            ? item.equipmentType
            : "多标的类型，详细查看详情"
        }}
      </template>
      <template slot="dueTime" slot-scope="{ item }"
        >{{ item.dueTime }}月</template
      >
      <template slot="operate" slot-scope="{ item }">
        <el-button
          v-if="
            (item.approvalStatus == 5 || item.approvalStatus == 3) &&
            handleHasPerms(`M26-insconfir_${$route.meta.policyType}`)
          "
          type="text"
          @click="offlineConfirm(item)"
          >{{ item.surePress ? "投保确认单" : "投保确认" }}</el-button
        >
        <el-button
          type="text"
          @click="handleChangeToInsuranceDoc(item.id)"
          v-if="
            handleHasPerms(`M26-insurance_${$route.meta.policyType}`) &&
            $route.meta.policyType === 1 &&
            (item.approvalStatus == 5 || item.approvalStatus == 3)
          "
          >生成报价单</el-button
        >
        <el-button
          type="text"
          @click="handleChangeToInsurance(item.id)"
          v-if="
            handleHasPerms(`M26-insurance_${$route.meta.policyType}`) &&
            $route.meta.policyType === 1 &&
            (item.approvalStatus == 5 || item.approvalStatus == 3)
          "
          >生成投保单</el-button
        >
        <el-button
          type="text"
          @click="
            (turnPolicyId = item.id),
              (isTurnPolicy = true),
              (bindUserId = item.bindUserId)
          "
          v-if="
            handleHasPerms(`M26-turn_${$route.meta.policyType}`) &&
            (item.approvalStatus == 5 || item.approvalStatus == 3)
          "
          >转保单</el-button
        >
        <!--  &&
            item.surePress&& item.enquireStatus != '成交' -->
        <el-button
          type="text"
          @click="handleEdit('details', item.enquiryNo, item.id)"
          v-if="handleHasPerms(`M26-details_${$route.meta.policyType}`)"
          >查看详情</el-button
        >
        <el-button
          type="text"
          v-if="
            handleHasPerms(`M26-edit_${$route.meta.policyType}`) &&
            (item.approvalStatus == 5 || item.approvalStatus == 3)
          "
          @click="handleCopy(item)"
          >复制</el-button
        >
        <el-button
          type="text"
          v-if="
            handleHasPerms(`M26-edit_${$route.meta.policyType}`) &&
            item.approvalStatus != 2
          "
          @click="handleEdit(item.id)"
          >编辑</el-button
        >
        <el-button
          type="text"
          v-if="
            handleHasPerms(`M26-edit_${$route.meta.policyType}`) &&
            item.approvalStatus == 2
          "
          @click="handleRevocation(item)"
          >撤回</el-button
        >
        <el-button
          type="text"
          @click="handleDel([item])"
          v-if="handleHasPerms(`M26-del_${$route.meta.policyType}`)"
          >删除</el-button
        >
      </template>
    </GeneralTable>
    <GeneralTable
      ref="GeneralTable"
      v-if="
        curType === 1 && handleHasPerms(`M26-draft_${$route.meta.policyType}`)
      "
      :column="column2"
      :option="option2"
      :dataList="dataList2"
      @search-change="handleSearch"
      @del-change="handleDel"
      tableName="enquiryManagementDraft"
      :totalNum="totalNum2"
    >
      <template slot="brandName" slot-scope="{ item }">
        {{
          item.isSingle !== false ? item.brandName : "多品牌类型，详细查看详情"
        }}
      </template>
      <template slot="frameNo" slot-scope="{ item }">
        {{
          item.isSingle !== false ? item.frameNo : "多标的类型，详细查看详情"
        }}
      </template>
      <template slot="equipmentType" slot-scope="{ item }">
        {{
          item.isSingle !== false
            ? item.equipmentType
            : "多型号类型，详细查看详情"
        }}
      </template>
      <template slot="operate" slot-scope="{ item }">
        <el-button
          type="text"
          v-if="handleHasPerms(`M26-edit_${$route.meta.policyType}`)"
          @click="handleEdit(item.id)"
          >编辑</el-button
        >
        <el-button
          type="text"
          @click="handleDel([item])"
          v-if="handleHasPerms(`M26-del_${$route.meta.policyType}`)"
          >删除</el-button
        >
      </template>
    </GeneralTable>
    <import-dialog v-model="isImport" @changeList="changeList" />
    <turn-policy
      v-model="isTurnPolicy"
      :id="turnPolicyId"
      :bindUserId="bindUserId"
      @changeList="changeList"
    />
    <allocationCustomerDialog
      :ids="customerIds"
      apiType="enquiry"
      v-model="isAllocationCustomer"
    />
    <offlineConfir
      v-model="openOfflineConfir"
      ref="offline"
      @changeList="changeList"
      :insid="insid"
    ></offlineConfir>
  </div>
</template>
<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import StatisticsBox from "@/components/StatisticsBox/index.vue";
import { hasPerms, dataPermissions } from "@/utils/auth";
import importDialog from "./components/importDialog.vue";
import allocationCustomerDialog from "@/views/policyManagement/components/allocationCustomerDialog";
import {
  enquiryList,
  delEnquiryList,
  changeToInsurance,
  exportEnquiryByIds,
  insEnquiryArchiveFast,
  dictionaryBatch,
  generateQuotationSheet,
  copyEnquireArchive,
  exportEnquiryExport,
  policyProcessRevoke,
  getEnquireArchiveDataStatistics,
  convertInsEnquiryToDraft,
} from "@/api/policy.js";
import { pageListToSelect } from "@/api/basicData.js";
import TurnPolicy from "@/views/policyManagement/components/TurnPolicy.vue";
import dayjs from "dayjs";
import offlineConfir from "@/views/EnquiryManagement/components/offlineConfir";
export default {
  name: "enquiryManagement1",
  components: {
    GeneralTable,
    StatisticsBox,
    importDialog,
    TurnPolicy,
    allocationCustomerDialog,
    offlineConfir,
  },
  data() {
    return {
      insid: "",
      openOfflineConfir: false,
      isImport: false,
      totalNum: 0,
      totalNum2: 0,
      dataList: [],
      dataList2: [],
      option: {
        isAdd: hasPerms(`M26-add_${this.$route.meta.policyType}`), //添加 事件 @add-change
        isDel: hasPerms(`M26-del_${this.$route.meta.policyType}`), //删除 @del-change
        isEdit: hasPerms(`M26-edit_${this.$route.meta.policyType}`), // 编辑 @edit-change
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        isImport: hasPerms(`M26-import_${this.$route.meta.policyType}`), // 刷新 @search-change
        isExport: hasPerms(`M26-export_${this.$route.meta.policyType}`), // 刷新 @search-change
        pageSize: 10,
        tableHeight: "calc(100vh - 122px)",
        searchList: [
          {
            label: "询价单号",
            prop: "enquiryNo",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入询价单号",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "enquiryNo"
            ),
          },
          {
            label: "测算单号",
            prop: "calculateNo",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入测算单号",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "calculateNo"
            ),
          },
          {
            label: "投保人",
            prop: "policyHolder",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入投保人",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "policyHolder"
            ),
          },
          {
            label: "手机号",
            prop: "contactPhone",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入手机号",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "phoneNum"
            ),
          },
          {
            label: "被保险人",
            prop: "insured",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入被保险人",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "insured"
            ),
          },
          {
            label: "险种名称",
            prop: "insuranceTypeName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入险种名称",
            isPermissions: this.$route.meta.policyType === 1,
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "insuranceTypeName"
            ),
          },
          {
            label: "险种类型",
            prop: "insuredCategoryName",
            clearable: true,
            placeholder: "请选择",
            formType: "select",
            filterable: true,
            selectList: [],
            isPermissions:
              this.$route.meta.policyType !== 1 &&
              dataPermissions(
                `M26_${this.$route.meta.policyType}-View`,
                "insuredCategory"
              ),
          },
          {
            label: "设备类型",
            prop: "deviceTypeName",
            formType: "select",
            filterable: true,
            selectList: [],
            placeholder: "请选择",
            isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "frameNo") && this.$route.meta.policyType != 3,
          },
          {
            label: "设备品牌",
            prop: "brandName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入设备品牌",
            isShow: this.$route.meta.policyType === 3 ? false : true,
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "brand"
            ),
            // prop: "brand",
            // formType: "select",
            // filterable: true,
            // selectList: [
            //   { value: "1", text: "三一重工" },
            //   { value: "2", text: "临工" },
            //   { value: "3", text: "美的" },
            // ],
          },
          {
            label: "设备型号",
            prop: "equipmentType",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入设备型号",
            isShow: this.$route.meta.policyType === 3 ? false : true,
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "equipmentType"
            ),
          },
          {
            label: "车架号",
            prop: "frameNo",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入车架号",
            isShow: true,
            // this.$route.meta.policyType === 3 ? false :
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "frameNo"
            ),
          },
          {
            label: "车牌号",
            prop: "plate",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入车牌号",
            isShow: this.$route.meta.policyType === 2 ? true : false,
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "plate"
            ),
          },
          {
            label: "保险公司",
            prop: "insuranceCompanyName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入保险公司",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "insuranceCompany"
            ),
          },
          {
            label: "分支机构",
            prop: "branchName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入分支机构",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "insuranceBranchName"
            ),
          },
          {
            label: "业务员",
            prop: "salesmanName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入业务员",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "salesmanName"
            ),
          },
          {
            label: "所属客户",
            prop: "bindUserName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "bindUser"
            ),
          },
          {
            label: "所属部门",
            prop: "deptName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "salesmanDeptName"
            ),
          },
          {
            label: "项目名称",
            prop: "projectNameList",
            formType: "cascader",
            formProps: {
              multiple: true,
              checkStrictly: true,
              emitPath: false,
              value: "projectName",
              label: "projectName",
              children: "subProjectList",
            },
            collapseTags: true,
            // filterable: true,
            showAllLevels: false,
            clearable: true,
            placeholder: "请选择",
            selectList: [],
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "projectName"
            ),
          },
          {
            label: "业务渠道",
            prop: "sourceChannelVal",
            formType: "select",
            filterable: true,
            placeholder: "请选择",
            selectList: [],
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "sourceChannel"
            ),
          },
          {
            label: "审核状态",
            prop: "processStatus",
            formType: "select",
            filterable: true,
            placeholder: "请选择",
            selectList: [
              { value: "", text: "全部" },
              { value: "5", text: "系统通过" },
              { value: "3", text: "人工通过" },
              { value: "2", text: "审核中" },
              { value: "1", text: "审核拒绝" },
              { value: "4", text: "已撤回" },
            ],
          },
          {
            label: "录单员",
            prop: "recorderName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入录单员",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "recorder"
            ),
          },
          {
            label: "业务代理人",
            prop: "upstreamAgentName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入业务代理人",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "upstreamAgentName"
            ),
          },
          {
            label: "询价单来源",
            prop: "fromSourceType",
            formType: "select",
            filterable: true,
            placeholder: "请选择",
            selectList: [
              { value: "", text: "全部" },
              { value: "1", text: "人工录入" },
              { value: "2", text: "一立云小程序" },
              { value: "3", text: "批量导入" },
            ],
            isPermissions: dataPermissions(
              `M20_${this.$route.meta.policyType}-View`,
              "fromSourceType"
            ),
          },
          {
            label: "成交状态",
            prop: "status",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "", text: "全部" },
              { value: "0", text: "未成交" },
              { value: "1", text: "成交" },
            ],
          },
          {
            label: "创建时间",
            formType: "daterange",
            clearable: true,
            format: "yyyy.MM.dd",
            prop: ["createTimeBegin", "createTimeEnd"],
            // propValue: [dayjs().format('YYYY-MM-DD 00:00:00'), dayjs().format('YYYY-MM-DD 23:59:59')], //2023-8-18 默认创建日期的话想改回不然每次直接搜索都需再按一遍取消创建日期
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "createTime"
            ),
          },
        ],
      },
      option2: {
        isDel: hasPerms(`M26-del_${this.$route.meta.policyType}`), //删除 @del-change
        isEdit: hasPerms(`M26-edit_${this.$route.meta.policyType}`), // 编辑 @edit-change
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        tableHeight: "calc(100vh - 122px)",
        pageSize: 10,
        searchList: [
          {
            label: "询价单号",
            prop: "enquiryNo",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入询价单号",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "enquiryNo"
            ),
          },
          {
            label: "业务类型",
            prop: "customerType",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "2", text: "单一业务" },
              { value: "3", text: "批量业务" },
            ],
          },
          {
            label: "投保人",
            prop: "policyHolder",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入投保人",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "policyHolder"
            ),
          },
          {
            label: "被保险人",
            prop: "insured",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入被保险人",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "insured"
            ),
          },
          //  {
          //    label: "受益人",
          //    prop: "charge",
          //    formType: "input",
          //    maxLength: 36,
          //    clearable: true,
          //    placeholder: "请输入受益人",
          //  },
          {
            label: "险种名称",
            prop: "insuranceTypeName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入险种名称",
            isPermissions:
              this.$route.meta.policyType === 1 &&
              dataPermissions(
                `M26_${this.$route.meta.policyType}-View`,
                "insuranceTypeName"
              ),
          },
          {
            label: "险种类型",
            prop: "insuredCategoryName",
            clearable: true,
            placeholder: "请选择",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "", text: "全部" },
              { value: "1", text: "平安保险" },
              { value: "2", text: "人寿保险" },
              { value: "3", text: "人地保险" },
              { value: "4", text: "中国平安保险（集团）股份有限公司" },
            ],
            isPermissions:
              this.$route.meta.policyType !== 1 &&
              dataPermissions(
                `M26_${this.$route.meta.policyType}-View`,
                "insuredCategory"
              ),
          },
          {
            label: "设备类型",
            prop: "deviceTypeName",
            formType: "select",
            filterable: true,
            selectList: [],
            placeholder: "请选择",
            isPermissions: dataPermissions(`M26_${ this.$route.meta.policyType }-View`, "brand") && this.$route.meta.policyType != 3,
          },
          {
            label: "设备品牌",
            prop: "brandName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入设备品牌",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "brand"
            ),
            // prop: "brand",
            // formType: "select",
            // filterable: true,
            // selectList: [
            //   { value: "1", text: "三一重工" },
            //   { value: "2", text: "临工" },
            //   { value: "3", text: "美的" },
            // ],
          },
          {
            label: "保险公司",
            prop: "insuranceCompanyName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入保险公司",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "insuranceCompany"
            ),
          },
          {
            label: "分支机构",
            prop: "branchName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入分支机构",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "insuranceBranchName"
            ),
          },
          {
            label: "车架号",
            prop: "frameNo",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入车架号",
            isShow: this.$route.meta.policyType === 3 ? false : true,
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "frameNo"
            ),
          },
          {
            label: "车牌号",
            prop: "plate",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
            isShow: this.$route.meta.policyType == 2,
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "plate"
            ),
          },
          {
            label: "业务员",
            prop: "salesmanName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入业务员",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "salesmanName"
            ),
          },
          {
            label: "所属客户",
            prop: "bindUserName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "bindUser"
            ),
          },
          {
            label: "所属部门",
            prop: "deptName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "salesmanDeptName"
            ),
          },
          {
            label: "项目名称",
            prop: "projectNameList",
            formType: "cascader",
            formProps: {
              multiple: true,
              checkStrictly: true,
              emitPath: false,
              value: "projectName",
              label: "projectName",
              children: "subProjectList",
            },
            collapseTags: true,
            clearable: true,
            // filterable: true,
            showAllLevels: false,
            placeholder: "请选择",
            selectList: [],
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "projectName"
            ),
          },
          {
            label: "业务渠道",
            prop: "sourceChannelVal",
            formType: "select",
            filterable: true,
            placeholder: "请选择",
            selectList: [],
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "sourceChannel"
            ),
          },
          {
            label: "审核状态",
            prop: "processStatus",
            formType: "select",
            filterable: true,
            placeholder: "请选择",
            selectList: [
              { value: "", text: "全部" },
              { value: "5", text: "系统通过" },
              { value: "3", text: "人工通过" },
              { value: "2", text: "审核中" },
              { value: "1", text: "审核拒绝" },
              { value: "4", text: "已撤回" },
            ],
          },
          {
            label: "录单员",
            prop: "recorderName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入录单员",
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "recorder"
            ),
          },
          {
            label: "创建时间",
            formType: "daterange",
            clearable: true,
            prop: ["begin", "end"],
            isPermissions: dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "createTime"
            ),
          },
        ],
      },
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "询价单号",
          prop: "enquiryNo",
          width: 280,
          isSlot: true,
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "enquiryNo"
          ),
        },
        {
          label: "测算单号",
          prop: "calculateNo",
          width: 280,
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "calculateNo"
          ),
        },
        {
          label: "投保人",
          prop: "policyHolder",
          width: 220,
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "policyHolder"
          ),
        },
        {
          label: "手机号",
          prop: "phoneNum",
          width: 120,
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "phoneNum"
          ),
        },
        {
          label: "被保险人",
          prop: "insured",
          width: 220,
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "insured"
          ),
        },
        {
          label: "设备品牌",
          prop: "brandName",
          isShow: true,
          width: 200,
          isSlot: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "brand"
          ),
        },
        {
          label: "设备型号",
          prop: "equipmentType",
          width: 200,
          isShow: true,
          isSlot: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "equipmentType"
          ),
        },
        {
          label: "设备类型",
          prop: "deviceTypeName",
          isShow: true,
          width: 200,
          isPermissions:
            dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "frameNo"
            ) && this.$route.meta.policyType !== 3,
        },
        {
          label: "车架号",
          prop: "frameNo",
          width: 200,
          isShow: true,
          isSlot: true,
          isPermissions:
            dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "frameNo"
            ) && this.$route.meta.policyType !== 3,
        },
        {
          label: "车牌号",
          prop: "plate",
          width: 200,
          isShow: true,
          isSlot: true,
          isPermissions:
            dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "plate"
            ) && this.$route.meta.policyType !== 1,
        },
        {
          label: "保险公司",
          prop: "insuranceCompanyName",
          width: 265,
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "insuranceCompany"
          ),
        },
        {
          label: "分支机构",
          prop: "insuranceBranchName",
          width: 265,
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "insuranceBranchName"
          ),
        },
        {
          label: this.$route.meta.policyType !== 1 ? "险种类型" : "险种名称",
          prop:
            this.$route.meta.policyType !== 1
              ? "insuredCategoryName"
              : "insuranceTypeName",
          width: 265,
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            this.$route.meta.policyType !== 1
              ? "insuredCategory"
              : "insuranceTypeName"
          ),
        },
        {
          label: "总保费(元)",
          prop: "totalPremium",
          isShow: true,
          width: 100,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "totalPremium"
          ),
        },
        {
          label: "保险期限",
          prop: "dueTime",
          isShow: true,
          isSlot: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "dueTime"
          ),
        },
        {
          label: "成交状态",
          prop: "enquireStatus",
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "enquireStatus"
          ),
        },
        {
          label: "询价单来源",
          prop: "enquireFromSourceTypeName",
          isShow: true,
          width: 100,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "enquireFromSourceTypeName"
          ),
        },
        {
          label: "所属客户",
          prop: "bindUserName",
          align: "center",
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "bindUser"
          ),
        },
        {
          label: "所属部门",
          prop: "salesmanDeptName",
          align: "center",
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "salesmanDeptName"
          ),
        },
        {
          label: "项目名称",
          prop: "projectName",
          align: "center",
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "projectName"
          ),
        },
        {
          label: "业务渠道",
          prop: "sourceChannel",
          align: "center",
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "sourceChannel"
          ),
        },
        {
          label: "业务员",
          prop: "salesmanName",
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "salesmanName"
          ),
        },
        {
          label: "业务代理人",
          prop: "upstreamAgentName",
          width: 100,
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "upstreamAgent"
          ),
        },
        {
          label: "审核状态",
          prop: "approvalStatusDesc",
          isShow: true,
          isSlot: true,
          // isPermissions: dataPermissions(`M26_${ this.$route.meta.policyType }-View`, "enquireStatus"),
        },
        {
          label: "投保确认状态",
          prop: "surePress",
          isShow: true,
          isSlot: true,
          // isPermissions: dataPermissions(`M26_${ this.$route.meta.policyType }-View`, "enquireStatus"),
        },
        {
          label: "录单员",
          prop: "recorderName",
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "recorder"
          ),
        },
        {
          label: "创建时间",
          prop: "createTime",
          width: 200,
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "createTime"
          ),
        },
        {
          label: "更新时间",
          width: 200,
          prop: "updateTime",
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "updateTime"
          ),
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 220,
          fixed: "right",
          isSlot: true,
        },
      ],
      column2: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "询价单号",
          prop: "enquiryNo",
          width: 180,
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "enquiryNo"
          ),
        },
        {
          label: "测算单号",
          prop: "calculateNo",
          width: 280,
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "calculateNo"
          ),
        },
        {
          label: "投保人",
          prop: "policyHolder",
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "policyHolder"
          ),
        },
        {
          label: "手机号",
          prop: "phoneNum",
          width: 120,
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "phoneNum"
          ),
        },
        {
          label: "被保险人",
          prop: "insured",
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "insured"
          ),
        },
        {
          label: "设备品牌",
          prop: "brandName",
          isShow: true,
          width: 200,
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "brand"
          ),
        },
        {
          label: "设备型号",
          prop: "equipmentType",
          isShow: true,
          width: 200,
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "equipmentType"
          ),
        },
        {
          label: "车架号",
          prop: "frameNo",
          isShow: true,
          width: 200,
          isShow: true,
          isPermissions:
            dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "frameNo"
            ) && this.$route.meta.policyType !== 3,
        },
        {
          label: "设备类型",
          prop: "deviceTypeName",
          isShow: true,
          width: 200,
          isPermissions:
            dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "frameNo"
            ) && this.$route.meta.policyType !== 3,
        },
        {
          label: "车牌号",
          prop: "plate",
          width: 200,
          isShow: true,
          isSlot: true,
          isPermissions:
            dataPermissions(
              `M26_${this.$route.meta.policyType}-View`,
              "plate"
            ) && this.$route.meta.policyType !== 1,
        },
        {
          label: "保险公司",
          prop: "insuranceCompanyName",
          width: 265,
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "insuranceCompany"
          ),
        },
        {
          label: "分支机构",
          prop: "insuranceBranchName",
          width: 265,
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "insuranceBranchName"
          ),
        },
        {
          label: this.$route.meta.policyType !== 1 ? "险种类型" : "险种名称",
          prop:
            this.$route.meta.policyType !== 1
              ? "insuredCategoryName"
              : "insuranceTypeName",
          width: 265,
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            this.$route.meta.policyType !== 1
              ? "insuredCategory"
              : "insuranceTypeName"
          ),
        },
        {
          label: "总保费(元)",
          prop: "totalPremium",
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "totalPremium"
          ),
        },
        {
          label: "保险期限",
          prop: "dueTime",
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "dueTime"
          ),
        },
        {
          label: "所属客户",
          prop: "bindUserName",
          align: "center",
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "bindUser"
          ),
        },
        {
          label: "所属部门",
          prop: "salesmanDeptName",
          align: "center",
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "salesmanDeptName"
          ),
        },
        {
          label: "项目名称",
          prop: "projectName",
          align: "center",
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "projectName"
          ),
        },
        {
          label: "业务员",
          prop: "salesmanName",
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "salesmanName"
          ),
        },
        {
          label: "录单员",
          prop: "recorderName",
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "recorder"
          ),
        },
        {
          label: "创建时间",
          prop: "createTime",
          width: 200,
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "createTime"
          ),
        },
        {
          label: "更新时间",
          prop: "updateTime",
          width: 200,
          isShow: true,
          isPermissions: dataPermissions(
            `M26_${this.$route.meta.policyType}-View`,
            "updateTime"
          ),
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 220,
          fixed: "right",
          isSlot: true,
        },
      ],
      curType: 0,
      dataList1: [],
      isTurnPolicy: false,
      turnPolicyId: "",
      bindUserId: "",
      isFast: false,
      oldData: {},
      customerIds: [],
      isAllocationCustomer: false,
      statisticsList: [
        {
          name: "询价单总数",
          value: 0,
        },
        {
          name: "总询价单保费",
          value: 0,
        },
        {
          name: "转保单总数",
          value: 0,
        },
        {
          name: "转保单达成率",
          value: 0,
          unit: "%",
        },
        {
          name: "转保单保费",
          value: 0,
        },
        {
          name: "询价单保费达成率",
          value: 0,
          unit: "%",
        },
      ],
      statisticsLoading: false,
      loginIdentity: "",
    };
  },
  created() {
    this.loginIdentity = this.$store.state.userInfo.loginIdentity;
    if (this.$route.meta.policyType == 2) {
      // let ind = this.column.findIndex((val) => val.label === "车架号");
      // this.column.splice(ind + 1, 0, {
      //   label: "车牌号",
      //   prop: "plate",
      //   align: "center",
      //   width: 200,
      //   isShow: true,
      // });
      // let ind2 = this.column2.findIndex((val) => val.label === "车架号");
      // this.column2.splice(ind2 + 1, 0, {
      //   label: "车牌号",
      //   prop: "plate",
      //   align: "center",
      //   width: 200,
      //   isShow: true,
      // });
      this.$forceUpdate();
    } else if (this.$route.meta.policyType == 3) {
      // let phoneInd = this.column.findIndex(
      //   (val) => val.label === "手机号"
      // );
      // this.column.splice(phoneInd, 1);
      let ind = this.column.findIndex((val) => val.label === "被保险人");
      let ind2 = this.column.findIndex((val) => val.label === "分支机构");

      // let moneyInd = this.column.findIndex(
      //   (val) => val.label === "总保费(元)"
      // );
      // this.column.splice(moneyInd, 1);
      this.column.splice(ind + 1, ind2 - ind - 1);

      let ind3 = this.column2.findIndex((val) => val.label === "被保险人");
      let ind4 = this.column2.findIndex((val) => val.label === "保险公司");
      // let phoneInd2 = this.column2.findIndex(
      //   (val) => val.label === "手机号"
      // );
      // let moneyInd2 = this.column2.findIndex(
      //   (val) => val.label === "总保费(元)"
      // );
      // this.column2.splice(moneyInd2, 1);
      // this.column2.splice(phoneInd2, 1);
      this.column2.splice(ind3 + 1, ind4 - ind3 - 1);
      this.$forceUpdate();
    }
    this.initSearch();
  },
  computed: {},
  methods: {
    // 获取设备品牌筛选字典
    initSearch() {
      // 证件类型identCardType 保险公司（insuranceCompany）、设备品牌（vehicleBrand）、设备类型（vehicleType）主险种编码：primaryInsuranceType 附加险种编码：attachInsuranceType 投保门店applicantStore 投保方式applicantMode 投保类型applicantType
      dictionaryBatch({
        codes:
          "vehicleBrand,insuranceCompany,CXInsCategory,FCXInsCategory,projectName,CXchannel,vehicleType,CXvehicleType",
      }).then((res) => {
        if (res && res.data) {
          const { vehicleBrand, insuranceCompany, projectName, CXchannel } =
            res.data;
          let ind = this.option.searchList.findIndex(
            (val) => val.label === "设备品牌"
          );
          let ind2 = this.option2.searchList.findIndex(
            (val) => val.label === "设备品牌"
          );
          this.option.searchList[ind].selectList = vehicleBrand.map((val) => {
            return {
              text: val.dictValue,
              value: val.dictKey,
            };
          });
          this.option.searchList[ind].selectList.unshift({
            text: "全部",
            value: "",
          });
          if (this.$route.meta.policyType != 3) {
            let indv = this.option.searchList.findIndex(
              (val) => val.label === "设备类型"
            );
            let indv2 = this.option2.searchList.findIndex(
              (val) => val.label === "设备类型"
            );
            let selectList = this.$route.meta.policyType == 2 ? res.data.CXvehicleType.map(
              (val) => {
                return {
                  text: val.dictValue,
                  value: val.dictValue,
                };
              }
            ) : res.data.vehicleType.map(
              (val) => {
                return {
                  text: val.dictValue,
                  value: val.dictValue,
                };
              }
            );
            selectList.unshift({
              text: "全部",
              value: "",
            });
            this.option.searchList[indv].selectList = selectList;
            this.option2.searchList[indv2].selectList = selectList;
          }
          this.option2.searchList[ind2].selectList = vehicleBrand.map((val) => {
            return {
              text: val.dictValue,
              value: val.dictKey,
            };
          });
          this.option2.searchList[ind2].selectList.unshift({
            text: "全部",
            value: "",
          });
          if (this.$route.meta.policyType != 1) {
            let ind8 = this.option.searchList.findIndex(
              (val) => val.label === "险种类型"
            );
            let ind9 = this.option2.searchList.findIndex(
              (val) => val.label === "险种类型"
            );
            this.option.searchList[ind8].selectList =
              this.$route.meta.policyType == 2
                ? res.data.CXInsCategory.map((val) => {
                    return {
                      text: val.dictValue,
                      value: val.dictValue,
                    };
                  })
                : res.data.FCXInsCategory.map((val) => {
                    return {
                      text: val.dictValue,
                      value: val.dictValue,
                    };
                  });
            this.option2.searchList[ind9].selectList =
              this.$route.meta.policyType == 2
                ? res.data.CXInsCategory.map((val) => {
                    return {
                      text: val.dictValue,
                      value: val.dictValue,
                    };
                  })
                : res.data.FCXInsCategory.map((val) => {
                    return {
                      text: val.dictValue,
                      value: val.dictValue,
                    };
                  });
            this.option.searchList[ind8].selectList.unshift({
              text: "全部",
              value: "",
            });
            this.option2.searchList[ind9].selectList.unshift({
              text: "全部",
              value: "",
            });
          }
          let index10 = this.option.searchList.findIndex(
            (val) => val.label === "业务渠道"
          );
          this.option.searchList[index10].selectList = res.data.CXchannel.map(
            (val) => {
              return {
                text: val.dictValue,
                value: val.dictValue,
              };
            }
          );
        }
      });
      pageListToSelect({
        projectName: "",
        bladeUserId: this.$store.state.userInfo.MJUserId,
      }).then((res) => {
        let ind3 = this.option.searchList.findIndex(
          (val) => val.label === "项目名称"
        );
        let ind4 = this.option2.searchList.findIndex(
          (val) => val.label === "项目名称"
        );
        this.option.searchList[ind3].selectList = res.data;
        this.option2.searchList[ind4].selectList = res.data;
      });
    },
    handleDel(data) {
      if (data && data.length) {
        this.$confirm("删除后数据不能恢复是否确认要删除？", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let temp = {
              ids: [],
            };

            data.forEach((element) => {
              temp.ids.push(element.id);
            });
            temp.ids = temp.ids.join(",");
            if (this.curType === 0) {
              convertInsEnquiryToDraft(temp.ids).then((res) => {
                if (res) {
                  this.$message({
                    message: "操作成功",
                    type: "success",
                  });
                  this.$refs.GeneralTable.getList();
                }
              });
            } else {
              delEnquiryList(temp.ids).then((res) => {
                if (res) {
                  this.$message({
                    message: "操作成功",
                    type: "success",
                  });
                  this.$refs.GeneralTable.getList();
                }
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message.error("请选择需要删除的数据！");
      }
    },
    handleEdit(type, enquiryNo, id) {
      if (type == "add") {
        console.log(this.$route.meta.policyRoute);
        this.$router.push({
          path: `/routerPolicyManagement/${this.$route.meta.policyRoute}/enquiryAdd/`,
        });
      } else if (type == "details") {
        this.$router.push({
          path:
            `/routerPolicyManagement/${this.$route.meta.policyRoute}/enquiryDetails/` +
            id,
        });
      } else {
        this.$router.push({
          path:
            `/routerPolicyManagement/${this.$route.meta.policyRoute}/enquiryEdit/` +
            type +
            "/" +
            this.curType,
        });
      }
    },
    handleCopy(e) {
      copyEnquireArchive({
        orderId: e.id,
      })
        .then((res) => {
          this.$message.success(res.msg);
          this.handleSearch(this.oldData);
        })
        .catch((err) => {
          this.$message.error("复制失败");
        });
    },
    handleSearch(data, pageNum, pageSize, searcType, isReset) {
      data.enquiryType = this.curType;
      data.current = data.pageNum;
      data.size = data.pageSize;
      data.frameNo = data.frameNo || data.frameNoLike || "";

      if (data.createTimeBegin) {
        data.createTimeBegin =
          dayjs(data.createTimeBegin).format("YYYY-MM-DD") + " 00:00:00";
        data.createTimeEnd =
          dayjs(data.createTimeEnd).format("YYYY-MM-DD") + " 23:59:59";
      }
      // if (isReset == "refresh") {
      //   if (this.isFast) {
      //     this.getList2(this.oldData);
      //   } else {
      //     this.getList(this.oldData);
      //   }
      // } else
      // if (isReset == "fuzzy") {
      //   this.isFast = true;
      //   this.oldData = JSON.parse(JSON.stringify(data));
      //   this.getList2(data);
      // } else {
      this.isFast = false;
      this.oldData = JSON.parse(JSON.stringify(data));
      this.getList(data);
      // }
    },
    getList(data) {
      data.category = this.$route.meta.policyType;
      this.statisticsLoading = true;
      if (data.projectNameList && data.projectNameList.length) {
        data.projectName = data.projectNameList.toString();
        delete data.projectNameList;
      }
      getEnquireArchiveDataStatistics({ ...data, current: "", size: "" })
        .then((res) => {
          this.statisticsLoading = false;
          this.statisticsList[0].value = res.data.total || 0;
          this.statisticsList[1].value = res.data.totalPremium || 0;
          this.statisticsList[2].value = res.data.changeToTotal || 0;
          this.statisticsList[3].value = res.data.changeToAchieveRate || 0;
          this.statisticsList[4].value = res.data.changeToTotalPremium || 0;
          this.statisticsList[5].value = res.data.premiumAchieveRate || 0;
        })
        .catch((err) => {
          this.statisticsLoading = false;
        });
      enquiryList(data).then((res) => {
        if (this.curType === 0) {
          this.dataList = res.data.records;
          this.totalNum = res.data.total;
        } else {
          this.dataList2 = res.data.records;
          this.totalNum2 = res.data.total;
        }
      });
    },
    getList2(data) {
      insEnquiryArchiveFast({
        current: data.pageNum,
        size: data.pageSize,
        // enquiryType: data.keyword,
        enquiryNo: data.keyword,
        policyHolder: data.keyword,
        // insured: data.keyword,
        insuranceTypeName: data.keyword,
        // insuranceCompany: data.keyword,
        salesmanName: data.keyword,
        frameNoLike: data.keyword,
        brandName: data.keyword,
        // equipmentType: data.keyword,
        recorderName: data.keyword,
        fastLike: data.keyword,
      }).then((res) => {
        if (this.curType === 0) {
          this.dataList = res.data.records;
          this.totalNum = res.data.total;
        } else {
          this.dataList2 = res.data.records;
          this.totalNum2 = res.data.total;
        }
      });
    },
    handleSelect(type) {
      this.curType = type;
      this.$refs.GeneralTable.getList();
    },
    importClick() {
      this.isImport = true;
    },
    changeList() {
      this.$refs.GeneralTable.getList();
    },
    handleExport(data) {
      // if (!data || !data.length) {
      //   this.$message.warning("请选择需要导出的询价单！");
      //   return false;
      // }
      let ids = data.map((val) => val.id).toString();
      exportEnquiryExport({
        category: this.$route.meta.policyType,
        ids,
        enquiryType: this.curType,
        ...this.oldData,
      }).then((res) => {
        if (res) {
          this.$message({
            message: "导出成功",
            type: "success",
          });
        }
      });
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },
    handleChangeToInsurance(id) {
      changeToInsurance({ id });
    },
    handleAllocationCustomer() {
      this.customerIds = this.$refs.GeneralTable.tableSelectionList.map(
        (val) => val.id
      );
      if (this.customerIds.length) {
        this.isAllocationCustomer = true;
      } else {
        this.$message.error("请选择询价单");
      }
    },
    handleChangeToInsuranceDoc(id) {
      generateQuotationSheet({ id });
    },
    handleRevocation(item) {
      this.$confirm("是否确认撤回？", "撤销", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          policyProcessRevoke({
            processType: 1,
            bussId: item.id,
            processId: item.processId,
          }).then((res) => {
            this.changeList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消撤回",
          });
        });
    },
    offlineConfirm(item) {
      if (item.surePress) {
        this.$refs.offline.isView = true;
      } else {
        this.$refs.offline.isView = false;
      }
      this.insid = item.id;
      this.openOfflineConfir = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.status {
  position: relative;
  color: #cccccc;
  padding-left: 18px;
  font-size: 14px;
  display: inline-block;
}
.status::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #cccccc;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.approval-status {
  position: relative;
  padding-left: 18px;
  font-size: 14px;
}
.approval-status::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.approval-status3 {
  color: #00bc0d;
}
.approval-status3::after {
  background-color: #00bc0d;
}
.approval-status2 {
  color: #0080ff;
}
.approval-status2::after {
  background-color: #0080ff;
}
.approval-status4 {
  color: #cccccc;
}
.approval-status4::after {
  background-color: #cccccc;
}
.approval-status1 {
  color: #c94242;
}
.approval-status1::after {
  background-color: #c94242;
}
.approval-status5 {
  color: #00bc0d;
}
.approval-status5::after {
  background-color: #00bc0d;
}
.type-select {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: -10px;
  border-radius: 10px 10px 0 0;
  background-color: #fff;
  .item {
    position: relative;
    padding: 13px 20px;
    color: #606266;
    margin-right: 20px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: #409eff;
    }
  }
  .active {
    color: #4278c9;
    font-weight: bold;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      border-radius: 4px;
      background-color: #4278c9;
    }
  }
}
</style>
