<template>
  <el-dialog
    width="60%"
    title="选择客户"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    custom-class="caluse-dialog"
    append-to-body
  >
    <div class="form-box">
      <!-- <el-select v-model="value" style="width: 490px" filterable clearable placeholder="请选择">
         <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
         </el-option>
      </el-select> -->
      <span></span>
      <div>
        <el-input
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          style="width: 180px; margin-right: 10px"
          @input="handleInput"
          v-model="formData.fastLike"
        >
        </el-input>
        <el-button
          type="primary"
          icon="el-icon-refresh"
          size="small"
          @click="getList"
        ></el-button>
      </div>
    </div>
    <el-table
      ref="singleTable"
      :data="tableData"
      highlight-current-row
      @current-change="handleCurrentChangeRow"
      style="width: 100%"
    >
      <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column property="userName" label="用户昵称"> </el-table-column>
      <el-table-column property="phoneNum" label="手机号"> </el-table-column>
      <el-table-column property="referrerName" label="推荐人">
      </el-table-column>
      <el-table-column property="customerTypeDesc" label="用户类型">
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="formData.current"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="formData.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
      <el-button @click="handleReset">重 置</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { pageMyCustomer, policyBindUser, enquiryBindUser } from "@/api/policy";
export default {
  props: {
    showDialog: Boolean,
    ids: {
      type: Array,
      default: () => [],
    },
    apiType: {
      type: String,
      default: "",
    },
  },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        } else {
          this.$emit("change", true);
        }
      },
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          this.currentRow = "";
          this.formData.size = 10;
          this.formData.current = 1;
          this.getList();
        } else {
          this.dialogVisible = false;
        }
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      options: [],
      value: "",
      tableData: [],
      currentRow: "",
      formData: {
        current: 1,
        size: 10,
        fastLike: "",
      },
      total: 0,
      timeObj: null,
    };
  },
  created() {},
  computed: {},
  methods: {
    getList() {
      pageMyCustomer(this.formData)
        .then((res) => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleCurrentChangeRow(val) {
      //console.log(val);
      //TODO 改多选
      this.currentRow = val;
    },
    handleSizeChange(val) {
      this.formData.size = val;
      this.formData.current = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.formData.current = val;
      this.getList();
    },
    handleInput() {
      clearTimeout(this.timeObj);
      this.timeObj = setTimeout(() => {
        clearTimeout(this.timeObj);
        this.formData.current = 1;
        this.getList();
      }, 500);
    },
    handleSubmit() {
      if (!this.currentRow) {
        this.$message.error("请选择客户");
        return false;
      }
      if (this.apiType == "policy") {
        policyBindUser({
          //TODO 改多选
          //  customerUid: this.currentRow.map((item) => {
          //    return item.userId;
          //  }),
          customerUid: this.currentRow.userId,
          policyIds: this.ids,
        })
          .then((res) => {
            this.$message.success("分配成功");
            this.dialogVisible = false;
          })
          .catch((err) => {
            this.$message.error("分配失败");
          });
      } else if (this.apiType == "enquiry") {
        enquiryBindUser({
          //TODO 改多选
          //  customerUid: this.currentRow.map((item) => {
          //    return item.userId;
          //  }),
          customerUid: this.currentRow.userId,
          enquireIds: this.ids,
        })
          .then((res) => {
            this.$message.success("分配成功");
            this.dialogVisible = false;
          })
          .catch((err) => {
            this.$message.error("分配失败");
          });
      } else {
        this.$emit("submit", this.currentRow);
      }
    },
    handleReset() {
      this.formData.size = 10;
      this.formData.current = 1;
      this.formData.fastLike = "";
      this.currentRow = "";
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .caluse-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    overflow: auto;
    padding: 24px;
  }
}
.form-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
</style>
